import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import _ from "lodash";
import useStyles from "./styles.js";
import TabPanel from "../../components/TabPanel";
import PlanningsTable from "../../components/Tables/Entities/Plannings";
import FabbisognoArticoliTable from "../../components/Tables/Entities/fabbisogno_articoli.jsx";
import RunPlanDetailCaricoMacchine from "../RunPlanDetailCaricoMacchine/index.jsx";

const TABS = [
  "Pianificazione",
  "Fabbisogno",
  "Carico Macchine"
];


export default function RunPlanDetail(props) {
  const classes = useStyles();

  const { runId } = props.match.params;

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newPage) => {
    setSelectedTab(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar
        position="static"
      >
        <Tabs 
          value={selectedTab} onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {TABS.map((e, i) => (
            <Tab label={e} key={i} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0} key={0}>
        <PlanningsTable runId={runId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1} key={1}>
        <FabbisognoArticoliTable runId={runId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2} key={2}>
        <RunPlanDetailCaricoMacchine runId={runId} />
      </TabPanel>
    </Grid>
  );
}
