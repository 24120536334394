import React, { useLayoutEffect, useRef } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import { addChartsLicense } from "../../utils/theme";


const FIELD_KEYS = {
  VALUE: 'saturazione',
  CATEGORY: 'cod_machine',
  DATE: 'monday_date',
  NAME: 'num_week'
};

const hexToRgb = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16));

const rgbToHex = ([r, g, b]) => 
  '#' + [r, g, b]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');

const linearGradient = (color1, color2, value, maxValue) => {
  color1 = hexToRgb(color1);
  color2 = hexToRgb(color2);

  const scale = 1 / maxValue;
  const w2 = value * scale;
  const w1 = 1 - w2;
  const rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];

  return rgbToHex(rgb);
}

function HeatMapChart({ data, cumulated, loading }) {
  am4core.useTheme(am4themes_animated);

  const chartRef = useRef(null);
  const chartId = `heatmap-chart${cumulated ? '-cumulated': ''}`;

  useLayoutEffect(() => {
    addChartsLicense();

    data = data
      .map(d => ({
        ...d,
        [FIELD_KEYS.DATE]: new Date(d[FIELD_KEYS.DATE]),
        [FIELD_KEYS.CATEGORY]: d[FIELD_KEYS.CATEGORY].trim()
      }))
      .sort((a, b) => b[FIELD_KEYS.DATE] - a[FIELD_KEYS.DATE])
      .map(d => ({
        ...d,
        [FIELD_KEYS.DATE]: d[FIELD_KEYS.DATE].toLocaleDateString('it-IT', {
          year: 'numeric', month: '2-digit', day: '2-digit'
        })
      }));

    if (cumulated) {
      let categories = data.reduce((categories, d) => {
        const category = d[FIELD_KEYS.CATEGORY];

        if (!categories[category]) {
          categories[category] = [];
        }
        categories[category].push(d);

        return categories;
      }, {});

      categories = _.values(categories).map(data =>
        data.reduce((acc, d) => {
          const currentIndex = acc.length;
          acc.push({
            ...d,
            [FIELD_KEYS.VALUE] : ((currentIndex ? acc[currentIndex-1][FIELD_KEYS.VALUE] * currentIndex : 0) + d[FIELD_KEYS.VALUE]) / (currentIndex + 1)
          });
          return acc; 
        }, [])
      );

      data = categories.reduce((data, category) => data.concat(category), []);
    }

    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.maskBullets = false;

    const title = chart.titles.create();
    title.text = `Heatmap${ cumulated ? ' Cumulated': ''}`;
    title.fontSize = 20;
    title.marginBottom = 30;

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = FIELD_KEYS.CATEGORY;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.disabled = 20000;

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = FIELD_KEYS.DATE;
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.minGridDistance = 30;
    yAxis.renderer.labels.template.tooltipText = `Week {${FIELD_KEYS.NAME}}`;
    yAxis.renderer.location = 0;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = FIELD_KEYS.CATEGORY;
    series.dataFields.categoryY = FIELD_KEYS.DATE;
    series.dataFields.value = FIELD_KEYS.VALUE;
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 1000;

    chart.maskBullets = false;

    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = `{${FIELD_KEYS.CATEGORY}}`;
    labelBullet.adapter.add("y", () =>  -15);

    const valueBullet = series.bullets.push(new am4charts.LabelBullet());
    valueBullet.label.text = `{${FIELD_KEYS.VALUE}} h`;

    const bgColor = new am4core.InterfaceColorSet().getFor("background");

    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 0.2;
    columnTemplate.stroke = bgColor;
    columnTemplate.tooltipText = `{${FIELD_KEYS.CATEGORY}}, Week {${FIELD_KEYS.NAME}}({${FIELD_KEYS.DATE}}): {${FIELD_KEYS.VALUE}} h`;
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);

    series.columns.template.column.adapter.add("fill", (fill, target) => {
      if (!target.dataItem) {
        return fill;
      }

      const value = target.dataItem.value;

      if (value <= 24) {
        return am4core.color(linearGradient("#00C853", "#FFD600", value, 24));
      }
      
      if (value <= 48) {
        return am4core.color(linearGradient("#FFD600", "#D50000", value - 24, 24));
      }

      return am4core.color("#D50000");
    });

    chart.data = data;
    chartRef.current = chart;

    return (() => {
      chart.dispose();
    });
  }, [ data, cumulated, loading ]);

  return (
    <div style={{ position: "relative", width: "100%", height: "850px"}}>
      {loading && (
        <div style={{ zIndex: 1000, display: "table", position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.87)"}}>
          <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
            <CircularProgress  />
          </div>  
        </div>
      )}
      <div 
        id={chartId} 
        style={{  zIndex: 1001, width: "100%", height: "100%" }}></div>
    </div>
  );
}

export default HeatMapChart;