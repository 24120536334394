import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "run";
// you might want to manually translate TITLE to Italian
const TITLE = "Pianificazione";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Numero Documento', field: 'cod_num_doc', type: 'number', editable: 'never'},
  { title: 'Registrazione', field: 'cod_numreg', type: 'number', editable: 'never' },
  { title: 'Num. Riga', field: 'cod_num_riga', type: 'number', editable: 'never' },
  { title: 'Esercizio', field: 'cod_esercizio', type: 'number', editable: 'never' },
  { title: 'Ord. Cliente / Prod', field: 'type_order', type: 'string', editable: 'never'},
  { title: 'Data Inizio Pianificata', field: 'tms_start_order', type: 'datetime', editable: 'never', defaultSort: 'asc'},
  { title: 'Data Fine Pianificata', field: 'tms_end_order', type: 'datetime', editable: 'never' },
  { title: 'Data Richiesta Consegna', field: 'tms_richiesta_consegna', type: 'datetime', editable: 'never' },
  { title: 'Giorni Differenza Consegna', field: 'day_diff_consegna', type: 'number', editable: 'never' },
];

const getData = async (runId) => await(getApi(`plannings/?run_id=${runId}`));

export default function PlanningsTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
