import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import _ from "lodash";
import useStyles from "./styles.js";
import HeatMapChart from "../../components/HeatMapChart/HeatMapChart";
import { any } from "prop-types";

const BASE_ENDPOINT = "carico_macchine";
const GROUPS_ENDPOINT = "groups";

const getGroups = async (runId) => await(getApi(`${BASE_ENDPOINT}/${GROUPS_ENDPOINT}/?run_id=${runId}`));
const getData = async (runId) => await(getApi(`${BASE_ENDPOINT}/?run_id=${runId}`));

export default function RunPlanDetailCaricoMacchine(props) {
  const classes = useStyles();

  const { runId } = props;
  const [groups, setGroups] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([])

  const [selectedGroup, setSelectedGroup] = useState(0);
  
  useEffect(() => (async () => {
    if (!runId) return;

    const groups = await getGroups(runId) || [];
    setGroups(groups);
  })(), [runId]);

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId) || [];
    console.log(response.data);
    setData(response.data);
  })(), [runId]);

  useEffect(() => {
    if (!groups.length) return;
    if (!data.length) return;

    const filteredData = data.filter(d => d.cod_department === groups[selectedGroup]);
    const weekFillers = filteredData.reduce((weeks, d) => {
      weeks[`${d.num_week}`] = {
        ...d,
        saturazione: 0,
        cod_machine: null
      }
      return weeks;
    }, {});
    const machines = filteredData.reduce((machines, d) => {
      machines.add(d.cod_machine)
      return machines;
    }, new Set());
    
    machines.forEach(m => {
      _.entries(weekFillers).forEach(([w, data]) => {
        console.log(data)
        if (!filteredData.some(d => d.cod_machine === m && d.num_week == w)) {
          filteredData.push({
            ...data,
            cod_machine: m
          })
        }
      })
    })

    setFilteredData(filteredData.map(d => ({ ...d, saturazione: d.saturazione * 24 })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groups, selectedGroup]);

  const handleTabChange = (event, newPage) => {
    setSelectedGroup(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar
        position="static"
      >
        <Tabs 
          value={selectedGroup} onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          style={{backgroundColor: '#FAFAFA', color: '#00475C'}}
        >
          {groups.map((e, i) => (
            <Tab label={e} key={e} />
          ))}
        </Tabs>
      </AppBar>
      <Grid container spacing={2} className={classes.content}>
        <HeatMapChart data={filteredData} style={{ height: '90%'}} />
      </Grid>
    </Grid>
  );
}
