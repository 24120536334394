import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: theme.spacing(0, 0),
    width: '100%'
  },
  content: {
    margin: theme.spacing(-1, 0),
    width: '100%'
  }
}));