import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { Typography, Slider } from "@material-ui/core";
import useStyles from "./styles.js";
import { postApi } from "../../utils/api/index.js";

const ENDPOINT = "runs_plan";

const createRun = async (params) => await(postApi(`${ENDPOINT}/`, params));

export default function RunAdd(props) {
  const classes = useStyles();
  const history = useHistory();
  
  const simulated = !!props.simulated;
  const runName = simulated ? 'Simulazione' : 'Pianificazione';

  const clientVsDeadlineParam = [
    {value: 0, label: 'Priorità cliente', db_value: 'CLIENT'},
    {value: 1, label: 'Bilanciato', db_value: 'BALANCED'},
    {value: 2, label: 'Rispetto consegne', db_value: 'DEADLINE'}
  ];

  const energyVsWasteParam = [
    {value: 0, label: 'Risparmio energetico', db_value: 'ENERGY'},
    {value: 1, label: 'Bilanciato', db_value: 'BALANCED'},
    {value: 2, label: 'Riduzione scarti', db_value: 'WASTE'}
  ];

  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ client_vs_deadline_param, setClientVsDeadlineParam ] = useState('BALANCED');
  const [ energy_vs_waste_param, setEnergyVsWasteParam ] = useState('BALANCED');

  const handleRunClick = async () => {
    const formState = {
      title: title,
      description: description,
      client_vs_deadline_param: client_vs_deadline_param,
      energy_vs_waste_param: energy_vs_waste_param
    }

    if (!title || !description) {
      return;
    }

    try {
      await createRun(formState);
    } catch (err) {
      console.error(err);
    } finally {
      history.replace('/runs_plan');
    }
  }

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <Grid item xs={6}>
      <Paper className={classes.paper}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              error={!title}
              id="title"
              label={`Titolo della ${runName}`}
              className={classes.textField}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{width: "100%"}}
              margin="normal"
              helperText="Obbligatorio"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!description}
              id="standard-multiline-static"
              label={`Descrizione della ${runName}`}
              multiline
              rows={10}
              style={{width: "100%"}}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              helperText="Obbligatorio"
            />
          </Grid>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Typography id="discrete-slider-restrict" gutterBottom>
              Parametro anagram
            </Typography>
            <Slider
              aria-labelledby="discrete-slider-restrict"
              step={null}
              marks={clientVsDeadlineParam}
              valueLabelDisplay="off"
              defaultValue={1}
              max={2}
              onChange={(e, val) => {setClientVsDeadlineParam(clientVsDeadlineParam[val].db_value)}}
              style={{width: "80%"}}
            />
          </Grid>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Typography id="discrete-slider-restrict" gutterBottom>
              Parametro anagram
            </Typography>
            <Slider
              aria-labelledby="discrete-slider-restrict"
              step={null}
              marks={energyVsWasteParam}
              valueLabelDisplay="off"
              defaultValue={1}
              max={2}
              onChange={(e, val) => {setEnergyVsWasteParam(energyVsWasteParam[val].db_value)}}
              style={{width:"80%"}}
            />
          </Grid>
          <Grid item xs={12} style={{textAlign: "right"}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRunClick}
          >
            Nuova {runName}
          </Button>
        </Grid>
        </Grid>
      </Paper>
      </Grid>
    </Grid>
  );
}
