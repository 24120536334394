import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "run";
const ENDPOINT = "runs_sched";
const TITLE = "Lista Schedulazioni";
const COLUMNS = [
  { title: 'Id', field: 'id', type: 'numeric', editable: 'never', defaultSort: 'desc' },
  { title: 'Id Pianificazione', field: 'id_plan', type: 'numeric', editable: 'never', render: rowData => (<Link to={`/runs_plan/${rowData.id_plan}`} style={{ color: '#212121', textDecoration: 'none' }}>{rowData.id_plan}</Link>) },
  { title: 'Titolo', field: 'title', type: 'string'},
  { title: 'Descrizione', field: 'description', type: 'string'},
  { title: 'Data', field: 'date', type: 'date', editable: 'never'},
  { title: 'Stato', field: 'status', type: 'string', editable: 'never' },
  { title: 'Utente', field: 'user_id', type: 'numeric', editable: 'never'},
  { title: 'Saturazione', field: 'saturazione', type: 'string', editable: 'never' },
  { title: 'OTIF', field: 'otif', type: 'string', editable: 'never' },
];

const getData = async () => await(getApi(`${ENDPOINT}/`));

export default function RunSchedTable(props) {
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      actions={[
        {
          icon: "launch",
          tooltip: "Dettagli",
          onClick: (event, rowData) => history.push(`/runs_sched/${rowData.id}`)
        }
      ]}
    />
  );
}