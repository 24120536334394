import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getApi, patchApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "run";
const ENDPOINT = "runs_plan";
const TITLE = "Lista pianificazioni";
const COLUMNS = [
  { title: 'Id', field: 'id', type: 'numeric', editable: 'never', defaultSort: 'desc' },
  { title: 'Titolo', field: 'title', type: 'string'},
  { title: 'Descrizione', field: 'description', type: 'string'},
  { title: 'Data', field: 'date', type: 'date', editable: 'never'},
  { title: 'Stato', field: 'status', type: 'string', editable: 'never' },
  { title: 'Utente', field: 'user_id', type: 'numeric', editable: 'never'},
  { title: 'Saturazione', field: 'saturazione', type: 'string', editable: 'never' },
  { title: 'OTIF', field: 'otif', type: 'string', editable: 'never' },
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}/`));
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);

export default function RunPlanTable(props) {
  const [data, setData] = useState([]);
  const history = useHistory();

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      actions={[
        {
          icon: "launch",
          tooltip: "Dettagli",
          onClick: (event, rowData) => history.push(`/runs_plan/${rowData.id}`)
        }, {
          icon: "broken_image",
          tooltip: "Broken Tables",
          onClick: (event, rowData) => history.push(`/runs_plan/${rowData.id}/broken`)
        }
      ]}
      editable={{
        onRowUpdate
      }}
    />
  );
}