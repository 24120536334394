import React, { useEffect, useState } from "react";
import AnagramGantt from "../../components/AnagramGantt";
import AnagramKpi, { KPI_NAMES } from "../../components/AnagramKpi";
import { getApi } from "../../utils/api";
import { AppBar, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Switch, Tab, Tabs } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import useStyles from "./styles.js";
import useSwitchStyles from "./switch.styles.js";

const BASE_ENDPOINT = "runs_sched";
const GROUPS_ENDPOINT = "groups";
const MACHINES_ENDPOINT = "machines";
const GANTT_BLOCKS_ENDPOINT = "gantt_blocks";
const KPIS_ENDPOINT = "kpis";

const START_DATE = "tms_start";
const END_DATE = "tms_end";

const AVG_DATE = "dat_average";
const AVG_VALUE = "val_average";

const getGroups = async (runId) => await(getApi(`${BASE_ENDPOINT}/${runId}/${GROUPS_ENDPOINT}`));
const getMachines = async (runId, ganttGroup) => await(getApi(
  `${BASE_ENDPOINT}/${runId}/${MACHINES_ENDPOINT}`,
  {gantt_group: ganttGroup}
));
const getGanttBlocks = async (runId, ganttGroup, machines) => await(getApi(
  `${BASE_ENDPOINT}/${runId}/${GANTT_BLOCKS_ENDPOINT}`,
  {gantt_group: ganttGroup, machines}
));
const getKpis = async (runId, kpiType, machines) => await(getApi(
  `${BASE_ENDPOINT}/${runId}/${KPIS_ENDPOINT}`,
  {kpi_type: kpiType, machines}
));


export default function RunSchedDetail(props) {
  const classes = useStyles();
  const switchClass = useSwitchStyles();

  const { runId } = props.match.params;
  const [ganttBlocks, setGanttBlocks] = useState([]);
  const [filteredGanttBlocks, setFilteredGanttBlocks] = useState([]);
  const [groups, setGroups] = useState([]);
  const [machines, setMachines] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [showGanttTable, setShowGanttTable] = useState(false);

  const [kpi, setKpi] = useState({});
  
  useEffect(() => (async () => {
    if (!runId) return;

    const groups = await getGroups(runId) || [];
    setGroups(groups);
  })(), [runId]);

  useEffect(() => (async () => {
    if (!groups.length) return;

    const machines = await getMachines(runId, groups[selectedGroup]) || [];
    setMachines(machines);
    setSelectedMachines(machines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [groups, selectedGroup]);

  useEffect(() => (async () => {
    if (selectedMachines.length === 0) {
      setGanttBlocks([]);
      return;
    }

    const ganttBlocks = await getGanttBlocks(runId, groups[selectedGroup], selectedMachines);
    setGanttBlocks(ganttBlocks);
    setFilteredGanttBlocks(_filterGanttBlocks(ganttBlocks));

    setDateRange([
      new Date(_.min(ganttBlocks.map(e => e[START_DATE]))),
      new Date(_.max(ganttBlocks.map(e => e[END_DATE])))
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [selectedMachines]);

  useEffect(() => (async () => {
    if (selectedMachines.length === 0) {
      setKpi({});
      return;
    }

    const [
      otif, wotif, sat, qual
    ] = await Promise.all([
      getKpis(runId, KPI_NAMES.otif, selectedMachines),
      getKpis(runId, KPI_NAMES.wotif, selectedMachines),
      getKpis(runId, KPI_NAMES.sat, selectedMachines),
      getKpis(runId, KPI_NAMES.qual, selectedMachines)
    ]);

    setKpi({ otif, wotif, sat, qual });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [selectedMachines]);

  useEffect(() => {
    setFilteredGanttBlocks(_filterGanttBlocks(ganttBlocks));
  }, [dateRange])

  const _filterGanttBlocks = (ganttBlocks) => ganttBlocks.filter(b => 
    new Date(b[END_DATE]) >= dateRange[0] &&
    new Date(b[START_DATE]) <= dateRange[1]
  );

  const kpiValue = (kpi) => kpi ? _.meanBy(kpi.filter(k =>
    new Date(k[AVG_DATE]) >= dateRange[0] &&
    new Date(k[AVG_DATE]) <= dateRange[1]
  ), AVG_VALUE) * 100 || 0 : 0;

  const handleTabChange = (event, newPage) => {
    setSelectedGroup(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar
        position="static"
      >
        <Tabs 
          value={selectedGroup} onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {groups.map((e, i) => (
            <Tab label={e} key={e} />
          ))}
        </Tabs>
      </AppBar>
      <Grid container spacing={2} className={classes.content}>
        <Grid item sm={12} md>
          <FormControl className={classes.formControl}>
            <InputLabel id="machine-select-label">Macchine</InputLabel>
            <Select
              labelId="machine-select-label"
              id="machine-select"
              multiple
              value={selectedMachines}
              onChange={event => setSelectedMachines(event.target.value)}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {machines.map(e => (<MenuItem value={e} key={e}>{e}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} md={2}>
          <DatePicker
            label="Da"
            value={dateRange[0]}
            onChange={value => setDateRange([value, value <= dateRange[1] ? dateRange[1] : value])}
            animateYearScrolling
            className={classes.formControl}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <DatePicker
            label="A"
            value={dateRange[1]}
            onChange={value => setDateRange([value >= dateRange[0] ? dateRange[0] : value, value])}
            animateYearScrolling
            className={classes.formControl}
          />
        </Grid>
        <Grid item sm>
          <Switch
            classes={switchClass}
            checked={showGanttTable}
            onChange={e => setShowGanttTable(e.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
        <AnagramGantt
          title={`Run #${runId}`}
          data={filteredGanttBlocks}
          showTable={showGanttTable}
        />
        </Grid>
        <Grid item sm={12} md={3}>
          <AnagramKpi 
            name={KPI_NAMES.otif}
            value={kpiValue(kpi?.otif)}
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <AnagramKpi
            name={KPI_NAMES.wotif}
            value={kpiValue(kpi?.wotif)}
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <AnagramKpi
            name={KPI_NAMES.sat}
            value={kpiValue(kpi?.sat)}
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <AnagramKpi
            name={KPI_NAMES.qual}
            value={kpiValue(kpi?.qual)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
