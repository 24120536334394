import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";
import FabbisognoOrdiniTable from "./fabbisogno_ordini";


const ENTITY = "Demands";
// you might want to manually translate TITLE to Italian
const TITLE = "Fabbisogno Articoli";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Articolo', field: 'cod_articolo', type: 'string', editable: 'never', defaultSort: 'asc'  },
  { title: 'Variante', field: 'cod_variante', type: 'string', editable: 'never' },
  { title: 'Versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Q.tà richiesta', field: 'qt_fabbisogno', type: 'numeric', editable: 'never' },
  { title: 'Q.tà giacenza', field: 'val_quantity_stock', type: 'numeric', editable: 'never' },
  { title: 'Q.tà in ordine', field: 'val_quantity_replenishment', type: 'numeric', editable: 'never' },
  { title: 'Q.tà da ordinare', field: 'qt_da_ordinare', type: 'numeric', editable: 'never' },
  { title: 'Data min richiesta', field: 'data_richiesta_minima', type: 'datetime', editable: 'never' },
  { title: 'Data max richiesta', field: 'data_richiesta_massima', type: 'datetime', editable: 'never' },
];

const getData = async (runId) => await(getApi(`fabbisogno_articoli/?run_id=${runId}`));
const getNestedData = async (runId) => await(getApi(`fabbisogno_ordini/?run_id=${runId}`));

export default function FabbisognoArticoliTable(props) {
  const [data, setData] = useState([]);
  const [nestedData, setNestedData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getNestedData(runId);
    setNestedData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      detailPanel={rowData => (
        <FabbisognoOrdiniTable
          data={nestedData.filter(d => 
            d.cod_articolo === rowData.cod_articolo
            && d.cod_variante === rowData.cod_variante
            && d.cod_versione === rowData.cod_versione
          )}
          style={{paddingLeft: '48px'}}
        />
      )}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
}
