import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { AbilityContext, defaultAbility, isUserAuthenticated } from './utils/authentication';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ToastContainer } from 'material-react-toastify';

import AppNavigation from "./components/AppNavigation";
import CanRoute from './components/AppNavigation/CanRoute';
import custom from "./custom";

import Login from "./containers/Login";
// import Home from "./containers/Home";
import RunSchedList from "./containers/RunSchedList";
import RunSchedDetail from "./containers/RunSchedDetail";
import DataEntry from "./containers/DataEntry";
import RunBrokenTables from "./containers/RunBrokenTables";

import RunPlanList from "./containers/RunPlanList";
import RunPlanAdd from "./containers/RunPlanAdd";
import RunPlanDetail from "./containers/RunPlanDetail";

import 'material-react-toastify/dist/ReactToastify.css';
import CustomerOrdersTable from "./components/Tables/Entities/CustomerOrders";


function App() {
  useEffect(() => {
    document.title = custom.app_title
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AbilityContext.Provider value={defaultAbility}>
          <Switch>
            <Route
              exact
              path="/login"
              render={() => {
              if (isUserAuthenticated()) {
                return (
                  <Redirect to="/" />
                );
              }

              return <Login />;
            }} />
            
            <Route>
              <AppNavigation />
              <Switch>
                {/* <CanRoute exact path="/" component={Home}/> */}
                <CanRoute exact path="/">
                  <Redirect to="/runs_plan" />
                </CanRoute>

                <CanRoute exact path="/runs_plan" I="read" a="run" component={RunPlanList}/>
                <CanRoute exact path="/runs_plan/new" I="create" a="run" component={RunPlanAdd}/>
                <CanRoute exact path="/runs_plan/:runId" I="read" a="run" component={RunPlanDetail}/>
                <CanRoute exact path="/runs_plan/:runId/broken" I="read" a="run" component={RunBrokenTables}/>

                <CanRoute exact path="/runs_sched" I="read" a="run" component={RunSchedList}/>
                <CanRoute exact path="/runs_sched/:runId" I="read" a="run" component={RunSchedDetail}/>

                <CanRoute exact path="/data" component={DataEntry}/>
                <CanRoute exact path="/orders_wallet" I="read" a="CustomerOrders" component={CustomerOrdersTable}/>

                <CanRoute>
                  <Redirect to="/" />
                </CanRoute>
              </Switch>
            </Route>
          </Switch>
        </AbilityContext.Provider>
      </MuiPickersUtilsProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
