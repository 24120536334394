import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "Demands";
// you might want to manually translate TITLE to Italian
const TITLE = "Fabbisogno Ordini";
const COLUMNS = [
  // { title: 'id_run', field: 'id_run', type: 'numeric', editable: 'never' },
  // { title: 'Articolo', field: 'cod_articolo', type: 'string', editable: 'never', defaultSort: 'asc'  },
  // { title: 'Variante', field: 'cod_variante', type: 'string', editable: 'never' },
  // { title: 'Versione', field: 'cod_versione', type: 'numeric', editable: 'never' },
  { title: 'Articolo Ordine', field: 'cod_articolo_ordine', type: 'string', editable: 'never' },
  { title: 'Esercizio', field: 'cod_esercizio', type: 'numeric', editable: 'never' },
  { title: 'Num riga', field: 'cod_num_riga', type: 'numeric', editable: 'never' },
  { title: 'Num documento', field: 'cod_num_doc', type: 'numeric', editable: 'never' },
  { title: 'Num operazione', field: 'num_oper', type: 'numeric', editable: 'never' },
  { title: 'Q.tà richiesta', field: 'qt_fabbisogno', type: 'numeric', editable: 'never' },
  { title: 'Data richiesta', field: 'data_richiesta', type: 'datetime', editable: 'never' },
];

export default function FabbisognoOrdiniTable(props) {
  const { data, ...rest } = props;

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      options={{
        showTitle: false,
        search: false,
        toolbar: false,
        exportMenu: [],
        paging: false,
        maxBodyHeight: 52*5,
      }}
      {...rest}
    />
  );
}
